import React from 'react';
import Body from './Body';
import Work from './Work';
import Myself from './Myself'; 
import 'font-awesome/css/font-awesome.min.css';

function Home({mode, showMenu, setShowMenu, language}) {

  let blurred = ""
  if (mode === "" && showMenu === false){
    blurred = ""
  } else if (mode === "" && showMenu === true) {
    blurred = "App-blurred"
  } else if ((mode === "-dark" && showMenu === true)) {
    blurred = "App-blurred-dark"
  }

    return (
          <div id="Home" onClick={() => setShowMenu(false)} className={blurred}>
            <Body mode={mode} language={language}/>
            <Work mode={mode} language={language}/>
            <Myself mode={mode} language={language}/>
          </div>
    );
}

export default Home
