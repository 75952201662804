import React from 'react'


function Footer() {
  return (
    <div className='footer'>
        <div className='footer-content'>Built & Designed by Felipe Saez</div>
    </div>
  )
}

export default Footer