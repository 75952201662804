import React from "react";
import "../modal.css";
import Logo from "../images/logos/github-mark-white.png";

function Modal({ setItem, item }) {
  const closeModal = () => {
    setItem(null);
  };
  return (
    <div className="modal">
      <div onClick={closeModal} className="overlay"></div>
      <div className="modal-content">
        <div className="item-name">
          <span className="name">{item.name}</span>
        </div>
        <div className="item-props">
          <div className="item-texts">
            <div>
              Problem: <span className="others">{item.context}</span>
            </div>
            <div>
              Stack: <span className="others">{item.technologies}</span>
            </div>
            <div>
              Source:{" "}
              <a href={item.source}>
                <img
                  alt={item.name}
                  src={Logo}
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
            </div>
            <br></br>
            {item.livedemo !== null ? (
              <a
                className="others"
                rel="noreferrer"
                target="_blank"
                href={item.livedemo}
              >
                <button>Live demo</button>
              </a>
            ) : null}
          </div>
          <div className="item-imgs">
            {item.modalImages.map((x, index) => {
              return (
                <img
                  key={index}
                  className="cardImage"
                  alt={item.name}
                  src={x}
                />
              );
            })}
          </div>
        </div>
        {/* <img className='cardImage' alt={item.name} src={item.images} /> */}
        <button onClick={closeModal} className="close-modal"></button>
      </div>
      <div className="modal-content-mobile">
        <div className="item-name-mobile">
          <span className="name-mobile">{item.name}</span>
        </div>
        <div className="item-props-mobile">
          <div className="item-texts">
            <div>
              Problem: <span className="others">{item.context}</span>
            </div>
            <div>
              Stack: <span className="others">{item.technologies}</span>
            </div>
            <div>
              Source:{" "}
              <a href={item.source}>
                <img
                  alt={item.name}
                  src={Logo}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
              <br></br>
            </div>
            {item.livedemo !== null ? (
              <a
                className="others"
                rel="noreferrer"
                target="_blank"
                href={item.livedemo}
              >
                <button>Live demo</button>
              </a>
            ) : null}
          </div>
        </div>
        <div className="item-imgs">
          {item.modalImages.map((x, index) => {
            return (
              <img key={index} className="cardImage" alt={item.name} src={x} />
            );
          })}
        </div>
        <button onClick={closeModal} className="close-modal"></button>
      </div>
    </div>
  );
}

export default Modal;
