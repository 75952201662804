import React, { useRef, useState } from "react";
import Modal from "./Modal";
import Carousel from "react-elastic-carousel";
import geofree1 from "../images/geofree/WelcomePage.png";
import geofree2 from "../images/geofree/Map.png";
import comercial from "../images/comercial/comrcial.png";
import comercial2 from "../images/comercial/comercial2.png";
import comercial3 from "../images/comercial/comercial3.png";
import hotel1 from "../images/hotel/hotel1.png";
import hotel2 from "../images/hotel/hotel2.png";
import hotel3 from "../images/hotel/hotel3.png";
import todo1 from "../images/todo/todo1.png";
import todo2 from "../images/todo/todo2.png";
import autocomplete1 from "../images/autocomplete/autocomplete1.png";
import autocomplete2 from "../images/autocomplete/autocomplete2.png";
import petfinderLogo from "../images/petfinder/PetFinderLogo.png";
import petfindermodal1 from "../images/petfinder/petfindermodal1.PNG";
import petfindermodal2 from "../images/petfinder/petfindermodal2.PNG";
import firebaselogo from "../images/logos/firebase.png";
import flutterflow from "../images/logos/flutterflow.jpeg";
import dockerlogo from "../images/logos/docker-mark-blue.png"
import css from "../images/logos/css.png";
import js from "../images/logos/js.png";
import django from "../images/logos/django.png";
import html from "../images/logos/html.png";
import react from "../images/logos/react.png";
import bootstrap from "../images/logos/bootstrap.png";

function Work({ mode, language }) {
  const [item, setItem] = useState(null);
  const carouselRef = useRef(null);
  let projects = [];

  if (language === "English") {
    projects = [
            {
        id: 1,
        name: "Pet Finder",
        description:
          "Mobile App that helps people to find and report lost pets, uses geolocation and image recognition",
        context:
          "App with user authentication that helps to locate lost pets by matching them to registry entries, utilizing Geolocation, Celery and Redis for background processing and delivering personalized results.",
        source: "https://github.com/Fsaez90/pet-finder-api",
        technologies: "Firebase, Flutterflow, Django Rest Frameworks, Postgis, Google Places API, Redis, Docker",
        cardimage: [petfinderLogo],
        modalImages: [petfindermodal1, petfindermodal2],
        logos: [firebaselogo, dockerlogo, flutterflow, django],
        livedemo: "https://pet-finder-h1ayy5.flutterflow.app/"
      },
      {
        id: 2,
        name: "Autocomplete Google API",
        description:
          "Web app that uses the Google Places API to get place suggestions predefined by the API.",
        context:
          "Address/location input field that, through the onChange() event, interacts with a Google API. This provides suggestions that, through the onSelect() event, obtain the respective coordinates, postal code, country, and display a map with the pin of the location/address.",
        source: "https://github.com/Fsaez90/autocomplete",
        technologies: "Google Places API, ReactJS, BootStrap",
        cardimage: [autocomplete1],
        modalImages: [autocomplete2, autocomplete1],
        logos: [html, css, js],
        livedemo: "https://thunderous-khapse-b64453.netlify.app/"
      },
      {
        id: 3,
        name: "Comercial S&B",
        description:
          "Web App that simulates the whole workflow of a machinery workshop.",
        context:
          "This is full-stack fully functional web app that takes client orders for machinery repair by filling and signing a form and issuing tickets that will be sent to technicians, it follows the workflow until the machine is ready to be picked up. It includes a signature pad. Developed for a chilean Technical Service Company.",
        source: "https://github.com/Fsaez90/comercialsyb-backend ",
        technologies: "ReactJS, Django Rest Framework",
        cardimage: [comercial],
        modalImages: [comercial2, comercial3],
        logos: [django, react, html, css, js],
        livedemo: null,
      },
      {
        id: 4,
        name: "Hotel registry",
        description:
          "Web App that tracks the guests registration of a hotel buffet-bareakfast service.",
        context:
          "I came up with this idea while in my current job working as an employee in a Berlin Hotel, the registation of the guests who desire to have breakfast buffet. This process is done manually so I decided to create an app that simulates the registration. The app works with DB which contains information of people who is either registered or not registered for breakfast.",
        source: "https://github.com/Fsaez90/BuffetRegisterApp",
        technologies: "ReactJS, Django Rest Framework",
        cardimage: [hotel1],
        modalImages: [hotel2, hotel3],
        logos: [django, react, html, css, js],
        livedemo: null,
      },
      {
        id: 5,
        name: "To-do app",
        description:
          "Basic handy app to keep track of things we've done and yet to be done.",
        context:
          "This is the very first developments in order to understand the relationship of HavaScript and the DOM.",
        source: "https://github.com/Fsaez90/todo.app",
        technologies: "HTML, CSS, JavaScript",
        cardimage: [todo1],
        modalImages: [todo1, todo2],
        logos: [html, css, js],
        livedemo: "https://cute-naiad-38df99.netlify.app/",
      },
      {
        id: 6,
        name: "GEOFREE",
        description:
          "Mapview app of freebies, uses Google Maps view & geo localization functionalities",
        context:
          "Geofree is a platform designed to facilitate the donation and collection of items left in the streets of Berlin. Collaborative Project where I built the whole Backend environment",
        source: "https://github.com/TechLabs-Berlin/wt23-geofree",
        technologies: "Django Rest Framework, ReactJS, CSS, Jupyter, Python",
        cardimage: [geofree1],
        modalImages: [geofree2],
        logos: [django, react, bootstrap, html, css, js],
        livedemo: "https://superlative-blancmange-aaefdc.netlify.app/",

      },
    ];
  } else {
    projects = [
      {
        id: 1,
        name: "Pet Finder",
        description:
          "Mobile App para reportar mascotas perdidas, geolocalización y reconoz=cimiento de imagen.",
        context:
          "Aplicación con autenticación de usuarios que ayuda a localizar mascotas perdidas mediante coincidencias en registros, utilizando geolocalización, Celery y Redis para procesamiento en segundo plano y entrega de resultados personalizados.",
        source: "https://github.com/Fsaez90/pet-finder-api",
        technologies: "Firebase, Flutterflow, Django Rest Frameworks, Postgis, Google Places API, Redis, Docker",
        cardimage: [petfinderLogo],
        modalImages: [petfindermodal1, petfindermodal2],
        logos: [firebaselogo, dockerlogo, flutterflow, django],
        livedemo: "https://pet-finder-h1ayy5.flutterflow.app/"
      },
      {
        id: 2,
        name: "Autocomplete Google API",
        description:
          "Webapp que ocupa Google Places API para obtener sugerencias de lugares pre definidas por la API",
        context:
          "Input field de direcciones/lugares que, mediante el evento onChange(), interactúa con una API de Google. Esta entrega sugerencias que, mediante el evento onSelect(), se obtienen las respectivas coordenadas, código postal, país y se despliega un mapa con el pin del lugar/dirección.",
        source: "https://github.com/Fsaez90/autocomplete",
        technologies: "Google Places API, ReactJS, BootStrap",
        cardimage: [autocomplete1],
        modalImages: [autocomplete2, autocomplete1],
        logos: [html, css, js],
        livedemo: "https://thunderous-khapse-b64453.netlify.app/",
      },
      {
        id: 3,
        name: "Comercial S&B",
        description:
          "Web App que simula todo el workflow de un taller de reparación de maquinaria.",
        context:
          "Esta es una aplicación web completamente funcional que toma pedidos de clientes para la reparación de maquinaria completando y firmando un formulario y emitiendo boletos que se enviarán a los técnicos, sigue el flujo de trabajo hasta que la máquina esté lista para ser recogida . Incluye pad de firma. Desarrollado para empresa chilena de Servicios Técnicos.",
        source: "https://getbootstrap.com/",
        technologies: "ReactJS, Django Rest Framework",
        cardimage: [comercial],
        modalImages: [comercial2, comercial3],
        logos: [django, react, html, css, js],
        livedemo: null,
      },
      {
        id: 4,
        name: "Registro the desayuno para Hotel",
        description:
          "Comprueba y cuenta las personas registradas o no registradas para desayunar buffet en un Hotel.",
        context:
          "Se me ocurrió esta idea en mi trabajo actual como empleado en un Hotel de Berlín, trabajo que incluye entre otras tareas, el registro de los huéspedes que desean desayunar buffet. Este proceso se realiza manualmente, así que decidí crear una aplicación que simule el registro. La aplicación funciona con base de datos que contiene información de personas que están registradas o no registradas para el desayuno.",
        source: "https://github.com/Fsaez90/BuffetRegisterApp",
        technologies: "ReactJS, Django Rest Framework",
        cardimage: [hotel1],
        modalImages: [hotel2, hotel3],
        logos: [django, react, html, css, js],
        livedemo: null,
      },
      {
        id: 5,
        name: "To-do app",
        description:
          "Aplicación práctica básica para realizar un seguimiento de las cosas que hemos hecho y aún por hacer.",
        context:
          "Este es mi primer desarrollo Frontend, me fue de gran ayuda para comprender en mayor extención, la relación de JavaScript, el DOM y el browser.",
        source: "https://github.com/Fsaez90/todo.app",
        technologies: "HTML, CSS, JavaScript",
        cardimage: [todo1],
        modalImages: [todo1, todo2],
        logos: [html, css, js],
        livedemo: "https://cute-naiad-38df99.netlify.app/",
      },
      {
        id: 6,
        name: "GEOFREE",
        description:
          "Map app para bienes en desuso, usa Google Maps view y geo-referencia para publicar y filtrar.",
        context:
          "Geofree es una plataforma diseñada para facilitar la donación y recolección de artículos dejados en las calles de Berlín. Proyecto colaborativo donde construí todo el entorno Backend",
        source: "https://github.com/TechLabs-Berlin/wt23-geofree",
        technologies: "Django Rest Framework, ReactJS, CSS, Jupyeter, Python",
        cardimage: [geofree1],
        modalImages: [geofree2],
        logos: [django, react, bootstrap, html, css, js],
        livedemo: "https://superlative-blancmange-aaefdc.netlify.app/",
      },
    ];
  }

  return (
    <div id="work" className={mode === "" ? "work" : `work${mode}`}>
      <div className="work-body">
        <div className="work-title">
          {language === "English" ? <p>MY WORK</p> : <p>PROYECTOS</p>}
        </div>
        <div className="slider">
          <Carousel transitionMs={1500} ref={carouselRef}>
            {projects.map((x, index) => {
              return (
                <div
                  onClick={() => setItem(x)}
                  className="CardContainer"
                  key={index}
                >
                  <div className="graphics">
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                      {x.cardimage.map((x, index) => {
                        return (
                          <div key={index}>
                            <img className="cardImage" alt={x.name} src={x} />
                          </div>
                        )
                      })}
                    </div>
                    <br></br>
                    <div className="logos" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                      {x.logos.map((c, index) => {
                        return (
                          <div key={index}>
                            <img className="logo" alt="logo" src={c} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <p className="projectName">{x.name}</p>
                  <p className="projectDescription">{x.description}</p>
                </div>
              );
            })}
          </Carousel>
        </div>
        {item !== null ? <Modal setItem={setItem} item={item} /> : null}
      </div>
      <div id="myself-line" className="separator"></div>
    </div>
  );
}

export default Work;
