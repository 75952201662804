
import { useState, useEffect } from 'react';
import './index.css';
import Contact from './components/Contact';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/Home';
import StickyBar from './components/Stickybar';
import Footer from './components/Footer';


function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [mode, setMode] = useState("")
  const [language, setLanguage] = useState("English")

  useEffect(() => {
    document.body.style.backgroundColor = mode === "-dark" ? "black" : "#17345a";
  }, [mode]); 

  const handleMenu = () => {
    if(showMenu === true) {
      setShowMenu(false)
    } else {
      return null
    }
  }

  console.log(language)
  
  return (
    <div className='App' onClick={handleMenu} >
      <Router>
      <StickyBar mode={mode} setMode={setMode} showMenu={showMenu} setShowMenu={setShowMenu} language={language} setLanguage={setLanguage} />
        <Routes>
          <Route path="/" element={<Home mode={mode} showMenu={showMenu} setShowMenu={setShowMenu} language={language}/>}/>
          <Route path="/contact" element={<Contact mode={mode} setMode={setMode} showMenu={showMenu} setShowMenu={setShowMenu} language={language} setLanguage={setLanguage}/>}/>
        </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
