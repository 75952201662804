import React from 'react';
import myPicture from '../images/myPicture1.jpg'

function Body({mode, language}) {

if (language === "English") {
  return (
    <div className={mode === ""? "body": `body${mode}`} id='body'>
        <div className='brief-description'>
            <img
            className='myImage'
            alt='my-self'
            src={myPicture}
            />
            <p className='text'>
              What's up! Felipe here, a web developer with a strong server-side foundation with Django, Django Rest Frameworks, Express Node and graphQl gateways. For the API endpoint integration I am very comfortable using ReactJS (fetch/ajax) combining all my knowledge of JavaScript, HTML, CSS (with responsive media queries), Bootstrap, Node packages modules, etc.
            <br />
            <br />
            I recently finished my digital shaper bootcamp at Techlabs Berlin (<a href='https://fsaez.pythonanywhere.com/static/pdf/techlabs_cert.pdf' >certificate</a>) and currently working as a junior backend/devops developer at an energy Startup in Berlin. 
            <br />
            <br />
              However, I'm always eager to take on new challenges and contribute to exciting projects. Needless to say that I really enjoy applying these abilities to chase ideas and solutions and put them on a device screen.
            </p>
        </div>
        <div  id="work-line" className='separator'></div>
    </div>
  )
} else {
  return (
    <div className={mode === ""? "body": `body${mode}`} id='body'>
        <div className='brief-description'>
            <img
            className='myImage'
            alt='my-self'
            src={myPicture}
            />
            <p className='text'>
              Qué tal! Mi nombre es Felipe, además de comer pizza por montones y salir a tomar fotografías análogas, soy un developer con una sólida base en tecnologías de front-end como JavaScript, HTML, CSS y React JS. También tengo conocimientos de backend utilizando Django y Django Rest Framework, y al igual que familiaridad para trabajar con Python. Como desarrollador de nivel de entrada, estoy ansioso por asumir nuevos desafíos y contribuir a proyectos emocionantes. 
            </p>
        </div>
        <div  id="work-line" className='separator'></div>
    </div>
  )
}
}

export default Body