import React from "react";
import ReactSwitch from "react-switch";
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Brightness2Icon from "@mui/icons-material/Brightness2";
import LightModeIcon from "@mui/icons-material/LightMode";
import DownloadIcon from "@mui/icons-material/Download";
import Logo from "../images/logos/github-mark-white.png";
import Linkedin from "../images/logos/linkedin.png";

function StickyBar({
  mode,
  setMode,
  showMenu,
  setShowMenu,
  language,
  setLanguage,
}) {
  function handleMenuToggle() {
    setShowMenu(!showMenu);
  }

  function darkModeHandle() {
    setMode((curr) => (curr === "" ? "-dark" : ""));
  }

  const style = {
    height: "40px",
    width: "40px",
  };
  if (language === "English") {
    return (
      <div className={showMenu ? "sticky-bar-side-menu" : `sticky-bar${mode}`}>
        <div className={showMenu ? "nav-bar" : `sticky-bar${mode}`}>
          <div className="nav-options-parent">
            <div className="nav-options">
              <NavLink to="/" className="option-desktop">
                <HomeIcon fontSize="medium" />
              </NavLink>
              <a className="option-desktop" href="/#myself-line">
                MYSELF
              </a>
              <a className="option-desktop" href="/#work-line">
                WORK
              </a>
              <NavLink to="/contact" className="option-desktop">
                CONTACT
              </NavLink>
              <a
                href="https://fsaez.pythonanywhere.com/static/pdf/cv_felipe_saez.pdf"
                className="option-desktop"
              >
                C.V<DownloadIcon></DownloadIcon>
              </a>
              <a href="https://github.com/Fsaez90">
                <img
                  alt="link-to-github"
                  src={Logo}
                  style={{ width: "20px", height: "20px", marginRight: "30px" }}
                />
              </a>
              <a href="https://www.linkedin.com/in/felipe-saez-125711181/">
                <img
                  alt="link-to-linkedin"
                  src={Linkedin}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
            </div>
          </div>
          <div className="toggle-parent">
            <div className="toggles">
              <div className="toggle-mode">
                <ReactSwitch
                  className="switch"
                  height={25.05}
                  width={56}
                  offColor={"#000000"}
                  onColor={"#17345a"}
                  handleDiameter={18}
                  onChange={darkModeHandle}
                  checked={mode === "-dark"}
                  uncheckedIcon={
                    <Brightness2Icon
                      className="sun"
                      style={{
                        width: "20px",
                        marginBottom: "5px",
                        marginLeft: "10px",
                        fill: "#f4f006",
                      }}
                    />
                  }
                  checkedIcon={
                    <LightModeIcon
                      className="moon"
                      style={{
                        width: "20px",
                        marginBottom: "5px",
                        marginLeft: "5px",
                        fill: "#c0b432",
                      }}
                    />
                  }
                />
              </div>
              <div className="lang-div">
                <select
                  className="language"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={language}
                  name="language"
                >
                  <option value="English">EN</option>
                  <option value="Spanish">ES</option>
                </select>
              </div>
            </div>
          </div>
          <div className="hamburger-parent">
            <div className="hamburger" onClick={handleMenuToggle}>
              <FontAwesomeIcon icon={faBars} style={style} />
            </div>
          </div>
        </div>
        {showMenu ? (
          <div className={mode === "" ? "side-menu" : `side-menu${mode}`}>
            <div className="side-content">
              <NavLink
                onClick={() => setShowMenu(false)}
                to="/"
                className="option-desktop"
              >
                <HomeIcon />
              </NavLink>
              <p onClick={() => setShowMenu(false)}>MYSELF</p>
              <p onClick={() => setShowMenu(false)}>WORK</p>
              <NavLink
                onClick={() => setShowMenu(false)}
                to="/contact"
                className="option-desktop"
              >
                CONTACT
              </NavLink>
              <a
                href="https://fsaez.pythonanywhere.com/static/pdf/cv_felipe_saez.pdf"
                rel="noreferrer"
                target="_blank"
                className="option-desktop"
              >
                RESUME <DownloadIcon></DownloadIcon>
              </a>
              <a href="https://github.com/Fsaez90">
                <img
                  alt="link-to-github"
                  rel="noreferrer"
                  target="_blank"
                  src={Logo}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/felipe-saez-125711181/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt="link-to-linkedin"
                  src={Linkedin}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className={showMenu ? "sticky-bar-side-menu" : `sticky-bar${mode}`}>
        <div className={showMenu ? "nav-bar" : `sticky-bar${mode}`}>
          <div className="nav-options-parent">
            <div className="nav-options">
              <NavLink to="/" className="option-desktop">
                <HomeIcon fontSize="large" />
              </NavLink>
              <a className="option-desktop" href="/#myself-line">
                ACERCA
              </a>
              <a className="option-desktop" href="/#work-line">
                PROYECTOS
              </a>
              <NavLink to="/contact" className="option-desktop">
                CONTACTO
              </NavLink>
              <a
                href="https://fsaez.pythonanywhere.com/static/pdf/cv_felipe_saez.pdf"
                rel="noreferrer"
                target="_blank"
                className="option-desktop"
              >
                C.V<DownloadIcon></DownloadIcon>
              </a>
              <a href="https://github.com/Fsaez90">
                <img
                  alt="link-to-github"
                  rel="noreferrer"
                  target="_blank"
                  src={Logo}
                  style={{ width: "30px", height: "20px", marginRight: "20px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/felipe-saez-125711181/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt="link-to-linkedin"
                  src={Linkedin}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
            </div>
          </div>
          <div className="toggle-parent">
            <div className="toggles">
              <div className="toggle-mode">
                <ReactSwitch
                  className="switch"
                  height={27.27}
                  width={56}
                  offColor={"#000000"}
                  onColor={"#17345a"}
                  handleDiameter={18}
                  onChange={darkModeHandle}
                  checked={mode === "-dark"}
                  uncheckedIcon={
                    <Brightness2Icon
                      className="sun"
                      style={{
                        width: "20px",
                        marginBottom: "5px",
                        marginLeft: "10px",
                        fill: "#f4f006",
                      }}
                    />
                  }
                  checkedIcon={
                    <LightModeIcon
                      className="moon"
                      style={{
                        width: "20px",
                        marginBottom: "5px",
                        marginLeft: "5px",
                        fill: "#c0b432",
                      }}
                    />
                  }
                />
                {/* <ReactSwitch height={30} width={66} offColor={"#000000"} onColor={"#17345a"} handleDiameter={25} onChange={darkModeHandle} checked={mode === "-dark"} uncheckedIcon={<Brightness2Icon style={{width: '20px', height: '20px', marginTop: '6px', marginLeft: '15px'}} />} checkedIcon={<LightModeIcon style={{width: '20px', height: '20px', marginTop: '6px', marginLeft: '6px'}}/>}/> */}
              </div>
              <div className="lang-div">
                <select
                  className="language"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={language}
                  name="language"
                >
                  <option value="English">EN</option>
                  <option value="Spanish">ES</option>
                </select>
              </div>
            </div>
          </div>
          <div className="hamburger-parent">
            <div className="hamburger" onClick={handleMenuToggle}>
              <FontAwesomeIcon icon={faBars} style={style} />
            </div>
          </div>
        </div>
        {showMenu ? (
          <div className={mode === "" ? "side-menu" : `side-menu${mode}`}>
            <div className="side-content">
              <NavLink
                onClick={() => setShowMenu(false)}
                to="/"
                className="option-desktop"
              >
                <HomeIcon />
              </NavLink>
              <p onClick={() => setShowMenu(false)}>ACERCA</p>
              <p onClick={() => setShowMenu(false)}>PROYECTOS</p>
              <NavLink
                onClick={() => setShowMenu(false)}
                to="/contact"
                className="option-desktop"
              >
                CONTACTO
              </NavLink>
              <a
                href="https://fsaez.pythonanywhere.com/static/pdf/cv_felipe_saez.pdf"
                rel="noreferrer"
                target="_blank"
                className="option-desktop"
              >
                C.V <DownloadIcon></DownloadIcon>
              </a>
              <a href="https://github.com/Fsaez90">
                <img
                  alt="link-to-github"
                  rel="noreferrer"
                  target="_blank"
                  src={Logo}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/felipe-saez-125711181/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt="link-to-linkedin"
                  src={Linkedin}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default StickyBar;
