import React, { useState } from 'react';

function Myself({mode, language}) {

  const [activeEvent, setActiveEvent] = useState(null);
  
  let events = []
  
  if (language === "English") {
    events = [
      {
        id: 1,
        title: 'Mulitech GmbH',
        briefDetails: 'Freelance & Collaboration',
        moreDetails: 'Small collaboration on a local startup by implementing address data validation at the registration process. Tasks included working on Django backend environment, integrating external address API and Playwright E2E testing frameworks.'
      },
      {
        id: 2,
        title: 'Energenious GmbH',
        briefDetails: 'Backend-DevOps engineer at an energy-sector Berlin Startup',
        moreDetails: 'API gateway developments, confection of stages for CI/CD pipelines and Docker images for microservices on a monorepo arquitechture product. Technologies used: Express Node, GraphQL, Apollo Federation, gitlab CICD, Docker.'
      },
      {
        id: 3,
        title: 'TechLabs enrollment',
        briefDetails: 'Backend web-dev digital shaper cert.',
        moreDetails: 'Commited to a 6 months web-dev bootcamp focusing on backend development, succesfully finishing with a full-stack fully deployed collaborative project.'
      },
      {
        id: 4,
        title: '2019 - Moved to Berlin',
        briefDetails: 'In 2019 I moved to Berlin',
        moreDetails: 'Living in Berlin made me have my first approach to dig up into web development, moved in in 2019. What was meant to be a one year travel visa turned into a long term professional projection, I currently hold a full working rights visa.'
      },
      {
        id: 5,
        title: '2015-2018 Europe and Oceania',
        briefDetails: 'From chasing wallabies on a beach to the cold scandinavian winds',
        moreDetails: 'Spent a bit more than 3 years doing Work and travel programs that allowed me to enrich my knowledge about different cultures, perspectives and human interactions.'
      },
      {
        id: 6,
        title: '2014 - A year Canada',
        briefDetails: 'The foundation of my English as a second language, between cougars and Grizzly bears',
        moreDetails: 'My very first trip abroad after graduating from University, landed in Calgary, AB but also gave myself a chance to live a couple of months in Montreal, QC. This trip triggered my hunger and passion for travelling and exploring.'
      },
      {
        id: 7,
        title: '2013 - Business Degree ',
        briefDetails: 'International Business Mgmt - Univiversidad de Valparaíso' ,
        moreDetails: 'Graduated on International Busines Management at Universidad de Valparaíso, Chile. One of the many traditional Universities of my hometown in Chile, even though I find myself on a career change, this experience allowed me to understand economics fields such as Finances, International Commerce, Marketing and so on.'
      }
    ];
  } else {
    events = [
      {
        id: 1,
        title: 'Mulitech GmbH',
        briefDetails: 'Freelance and colaboración',
        moreDetails: 'Pequeña colaboración en una startup local implementando la validación de datos de direcciones en el proceso de registro. Las tareas incluyeron trabajar en el entorno backend de Django, integrar una API externa de direcciones y marcos de prueba E2E de Playwright.'
      },
      {
        id: 2,
        title: 'Energenious GmbH',
        briefDetails: 'Desarrollaor Backend-DevOps en una startup enfocada al area energética',
        moreDetails: 'API gateway developments, confección fe CI/CD pipelines con imágenes de Docker para microservicios en un ambiente Monorepo. Tech-stack: Express Node, GraphQL, Apollo Federation, gitlab CICD, Docker.'
      },
      {
        id: 3,
        title: 'Curso de Backend en TechLans, Berlin',
        briefDetails: 'Curso de web-dev enfocado en backend en TechLabs, Berlin',
        moreDetails: 'Enrrolado un bootcamp de desarrollo web de 6 meses que se enfocado en backend, finalizado con éxito tras un proyecto colaborativo full-stack totalmente desplegado en la nube'
      },
      {
        id: 4,
        title: '2019 - Arrivo a Alemania',
        briefDetails: 'El 2019 me mudé a Berlin con una visa temporal',
        moreDetails: 'Vivir en Berlín me hizo tener mi primer acercamiento para profundizar en el desarrollo web, me mudé en 2019. Lo que debía ser una visa de viaje de un año se convirtió en una proyección personal y profesional a largo plazo.'
      },
      {
        id: 5,
        title: '2015-2018 Entre Europa y Oceanía',
        briefDetails: 'Desde las playas con canguros a los helados vientos escandinavos',
        moreDetails: 'Pasé un poco más de 3 años haciendo programas de trabajo y viajes que me permitieron enriquecer mi conocimiento sobre diferentes culturas, perspectivas e interacciones humanas. 2 años en Australia y 1 año en Dinamarca.'
      },
      {
        id: 6,
        title: '2014 - Un año en Canadá',
        briefDetails: 'La base de mi inglés como segundo idioma, entre pumas y osos grizzly',
        moreDetails: 'Mi primer viaje al extranjero después de graduarme de la Universidad, aterrizó en Calgary, AB, pero también me dio la oportunidad de vivir un par de meses en Montreal, QC. Este viaje desencadenó mi hambre y pasión por viajar y explorar.'
      },
      {
        id: 7,
        title: '2013 - Graduado en Negocios Internacionales',
        briefDetails: 'Titulado después de 5 años en la Universidad' ,
        moreDetails: 'Titulado de Administración de Negocios Internacionales de la Universidad de Valparaíso, Chile. Una de las muchas universidades tradicionales de mi ciudad natal en Chile, aunque me encuentro en un cambio de enfoque profesional, esta experiencia me permitió comprender campos de la economía como Finanzas, Comercio Internacional, Marketing, etc.'
      }
    ];
  }


  const handleEventClick = (id) => {
    setActiveEvent(activeEvent === id ? null : id);
  };


  return (
    <div id='myself' className={mode === ""? "myself": `myself${mode}`}>
        <div className='myself-body'>
            <div className='work-title'>
            {(language === "English")?<p>A BIT MORE ABOUT MYSELF</p>: <p>UN POCO MÁS SOBRE MI</p>}
            </div>
            <div className="container">
              {events.map((event) => (
               <div
               className={`event ${activeEvent === event.id ? 'active' : ''}`}
               style={{ height: activeEvent === event.id ? 'auto' : 'auto' }}
               key={event.id}
               onClick={() => handleEventClick(event.id)}>
                  <h2>{event.title}</h2>
                  <p>{event.briefDetails}</p>
                  <div className="details">{event.moreDetails}</div>
                </div>
              ))}
           </div>
        </div>
        <br />
        <br />
        <br />
    </div>
  )
}

export default Myself