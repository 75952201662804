import React, { useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import StickyBar from './Stickybar';


function Contact({mode, setMode, showMenu, setShowMenu, language, setLanguage}) {
  const navigate = useNavigate();
  let blurred = ""
  if (mode === "" && showMenu === false){
    blurred = ""
  } else if (mode === "" && showMenu === true) {
    blurred = "App-blurred"
  } else if ((mode === "-dark" && showMenu === true)) {
    blurred = "App-blurred-dark"
  }

  const [name, setName] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [message, setMessage] = useState();
  const [file, setFile] = useState(null);
  const [success, setSuccess] = useState("overlayed")
  const [succesMsg, setSuccessMsg] = useState("success-msg")
  const ref = useRef();

  const reset = (e) => {
    e.preventDefault();
    ref.current.value = null;
    setFile(null) 
  };

  // const submitHandle = (event) => {
  //   event.preventDefault()
  //   const uploadData = new FormData();
  //   //for loop over myImages array
  //   for(let i = 0; i < file.length; i++) {
  //     uploadData.append(`uploaded_files[${i}]`, file[i], file.name)
  //   }
  //   uploadData.append("name", name);
  //   uploadData.append("lastname", lastname);
  //   uploadData.append("email", email);
  //   uploadData.append("company", company);
  //   uploadData.append("message", message);
  //   fetch('https://fsaez.pythonanywhere.com//api/message-create/', {
  //     method: "POST",
  //     body: uploadData,
  //   })
  //     .then(response => console.log(response))
  //     .catch((error) => console.log(error));
  // };

    const submitHandle = (event) => {
      event.preventDefault()
      const uploadData = new FormData();
      //for loop over myImages array
      if(file === null){
        //pass
      } else {
        for (const files of file) {
          uploadData.append('files', files);
        }
      }
      uploadData.append("name", name);
      uploadData.append("lastname", lastname);
      uploadData.append("email", email);
      uploadData.append("company", company);
      uploadData.append("message", message);
      fetch('https://fsaez.pythonanywhere.com/api/send-email/', {
        method: "POST",
        body: uploadData,
      })
        .then(response => console.log(response))
        .catch((error) => console.log(error));
        setSuccess("overlay-active")
        setSuccessMsg("success-msg-active")
        setTimeout(() => {
          navigate("/")
        }, 2500); 
  };

  return (
    <div className='form-component'>
      <StickyBar language={language} setLanguage={setLanguage} mode={mode} setMode={setMode} showMenu={showMenu} setShowMenu={setShowMenu}/>
      <div id="form-div" onClick={() => setShowMenu(false)} className={blurred}>
          <div className={mode === ""? "promt-message": `promt-message${mode}`}>
            <div className='form-title'> 
            {(language === "English")?<p>SHOOT ME A MESSAGE</p>: <p>DÉJAME UN MENSAJE</p>} 
            </div>
            {(language === "English")?
                <ul>
                    <li>Interested in collaborations?</li>
                    <li>Recruiter passing by?</li>
                </ul>
              :
                <ul>
                  <li>¿Buscas team para tu proyecto?</li>
                  <li>¿Eres agente de RRHH?</li>
                </ul>
              }
          </div>
          {(language === "English")?
                <form onSubmit={submitHandle} className={mode === ""? "formulario": `formulario${mode}`}>
                  <label htmlFor="name">Name *</label>
                  <input type="text" id='name' onChange={(e) => setName(e.target.value)} name="name" required/>
                  <br />
                  <label htmlFor='lastname'>Lastname *</label>
                  <input type="text" id='lastname' onChange={(e) => setLastname(e.target.value)} name="lastname" required/>
                  <br />
                  <label htmlFor='email'>Email *</label>
                  <input type="email" id='email' onChange={(e) => setEmail(e.target.value)} name="email" required/>
                  <br />
                  <label htmlFor='company'>Company</label>
                  <input type="text" id='company' onChange={(e) => setCompany(e.target.value)} name="company"/>
                  <br />
                  <br />
                  <label className="file-button"  htmlFor='file'>Upload file</label>
                  <input  type="file" id='file' ref={ref} onChange={(e) => setFile(e.target.files)} name="files" multiple/>
                  <br />
                  {file?<button className="delete-button" onClick={reset}>Delete</button>: null}
                  <br />
                  <label className="message-input" htmlFor='message'>Message *</label>
                  <textarea id='message' required onChange={(e) => setMessage(e.target.value)} name="message" />
                  <br />
                  <input className="form-button" type="submit" name="submit" value="SEND"/>
            </form>:
            <form onSubmit={submitHandle}  className={mode === ""? "formulario": `formulario${mode}`}>
                <label htmlFor="name">Nombre *</label>
                <input type="text" id='name' onChange={(e) => setName(e.target.value)}  required/>
                <br />
                <label htmlFor='lastname'>Apellidos *</label>
                <input type="text" id='lastname' onChange={(e) => setLastname(e.target.value)} required/>
                <br />
                <label htmlFor='email'>Email *</label>
                <input type="email" id='email' onChange={(e) => setEmail(e.target.value)}  required/>
                <br />
                <label htmlFor='company'>Empresa</label>
                <input type="text" id='company' onChange={(e) => setCompany(e.target.value)} />
                <br />
                <br />
                <label htmlFor='file'>Archivo</label>
                <input className="file-button" type="file" id='file' ref={ref} onChange={(e) => setFile(e.target.files)} multiple/>
                <br />
                {file?<button className='delete-button' onClick={reset}>Delete</button>: null}
                <br/>
                <label className="message-input" htmlFor='message'>Mensaje *</label>
                <textarea id='message' required/>
                <br />
                <input className="form-button" type="submit" value="Enviar"/>
          </form>
          }
        </div>
        <br />
        <br />
        <br />
        <div id={mode === ""? success: `${success}${mode}`}>
          <div className="success-wrapper">
            <p id={succesMsg}>Thanks for reaching out! Will get back to you soon.</p>
          </div>
        </div>
    </div>
  ) 
}

export default Contact